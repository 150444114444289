import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // height: 400,
    // height: 600,
    width: '100%',
    maxWidth: '900px',
    backgroundColor: theme.palette.background.paper
  }
}));

const AssetTab = ({ assetTabIndex, setAssetTabIndex }) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setAssetTabIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={assetTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Location" {...a11yProps(1)} />
          <Tab label="Logs" {...a11yProps(2)} />

          {/* <Tab label="Link Asset" {...a11yProps(1)} /> */}
          {/* <Tab label="Team" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
    </div>
  );
};

export default AssetTab;

AssetTab.propTypes = {
  assetTabIndex: PropTypes.any,
  setAssetTabIndex: PropTypes.func
};
