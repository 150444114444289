import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../shared/inputs/Input';

import {
  Button,
  Card,
  CardContent,
  Checkbox,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import assetService from '../../../../services/AssetService';
import toast from 'react-hot-toast';
import { formattedAssets } from '../../table/hepler';
import { AssetIcon } from '../../assetIcon/AssetIcon';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2]
  },
  card: {
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2]
  },
  cardContent: {
    padding: theme.spacing(3)
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  input: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  dialogActions: {
    padding: theme.spacing(2)
  },

  gridFixedLocationItem: {
    marginTop: '1rem',
    marginLeft: '1rem'
  }
}));

const AssetLocation = ({ row, onCancelChanges, setRows, rows }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [checkedState, setCheckedState] = useState({
    beaconChecked: row?.beaconTokenMac || false,
    trackerChecked: row?.trackerTokenMac || false,
    fixedLocationChecked: row?.fixedLocation || false
  });
  const [tokenMacInput, setTokenMacInput] = useState({
    beaconTokenMac: row?.beaconTokenMac ? row?.tokenMac : '',
    trackerTokenMac: row?.trackerTokenMac ? row?.tokenMac : ''
  });
  const [mapIcon, setMapIcon] = useState(row?.mapIcon || '');

  const handleTokenMac = (e, name) => {
    if (name === 'beaconTokenMac') {
      setTokenMacInput({ ...tokenMacInput, [name]: e.target.value, ['trackerTokenMac']: '' });
    }
    if (name === 'trackerTokenMac') {
      setTokenMacInput({ ...tokenMacInput, [name]: e.target.value, ['beaconTokenMac']: '' });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'beaconChecked') {
      setCheckedState({ ...checkedState, [e.target.name]: e.target.checked, ['trackerChecked']: false });
      setTokenMacInput({ ...tokenMacInput, ['trackerTokenMac']: '' });
    }
    if (e.target.name === 'trackerChecked') {
      setCheckedState({ ...checkedState, [e.target.name]: e.target.checked, ['beaconChecked']: false });
      setTokenMacInput({ ...tokenMacInput, ['beaconTokenMac']: '' });
    }
    if (e.target.name === 'fixedLocationChecked') {
      setCheckedState({ ...checkedState, [e.target.name]: e.target.checked });
    }
  };

  const handleSelectedIcon = (selectedOption) => {
    setMapIcon(selectedOption?.value || null);
  };

  const handleAssetUpdate = () => {
    if (!row?.assetId) {
      return toast.error('No assetId found to update location information');
    }

    let assetPayload;

    if (mapIcon) {
      assetPayload = {
        assetId: row?.assetId,
        fixedLocation: checkedState.fixedLocationChecked,
        beaconTokenMac: checkedState.beaconChecked,
        trackerTokenMac: checkedState.trackerChecked,
        tokenMac: checkedState.beaconChecked
          ? tokenMacInput?.beaconTokenMac
          : checkedState.trackerChecked
          ? tokenMacInput?.trackerTokenMac
          : '',
        mapIcon: mapIcon
      };
    } else {
      assetPayload = {
        assetId: row?.assetId,
        fixedLocation: checkedState.fixedLocationChecked,
        beaconTokenMac: checkedState.beaconChecked,
        trackerTokenMac: checkedState.trackerChecked,
        tokenMac: checkedState.beaconChecked
          ? tokenMacInput?.beaconTokenMac
          : checkedState.trackerChecked
          ? tokenMacInput?.trackerTokenMac
          : ''
      };
    }

    assetService
      .updateAssetTokenMac(assetPayload)
      .then((data) => {
        const assetRecord = data?.data?.data;
        const assetArray = [];
        assetArray.push(assetRecord);
        const formattedAsset = formattedAssets(assetArray)[0];

        const updatedRows = rows.map((row) => {
          if (row.id === formattedAsset.id) {
            return { ...row, ...formattedAsset };
          }
          return row;
        });

        setRows(updatedRows);
        return toast.success("Successfully updated asset's location data");
      })
      .catch((error) => {
        return toast.error(
          error?.response?.data?.message || 'Could not find assetId to update the location of the asset'
        );
      });
  };

  return (
    <div style={{ height: '700px' }}>
      <DialogContent>
        <Paper className={classes.paper}>
          <Grid container spacing={1}>
            <Grid container item spacing={1}>
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Grid item style={{ display: 'flex', flexDirection: isSmall ? 'column' : 'row' }}>
                      {/* asset icon */}
                      <Grid item xs={12}>
                        <AssetIcon handleSelectedIcon={handleSelectedIcon} mapIcon={mapIcon} />
                      </Grid>

                      {/* Fixed Location  Section */}
                      <Grid item xs={12} className={classes.gridFixedLocationItem}>
                        <Grid item container spacing={2}>
                          <Grid item xs={12}>
                            <div className={classes.checkboxLabel}>
                              <Checkbox
                                name="fixedLocationChecked"
                                checked={checkedState.fixedLocationChecked}
                                color="primary"
                                inputProps={{ 'aria-label': 'Fixed Location checkbox' }}
                                onChange={handleChange}
                              />
                              <Typography variant="body1">Fixed Location</Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid container item spacing={1}>
              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    {/* Beacon Token Mac Section */}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className={classes.checkboxLabel}>
                          <Checkbox
                            name="beaconChecked"
                            checked={checkedState.beaconChecked}
                            color="primary"
                            inputProps={{ 'aria-label': 'Beacon checkbox' }}
                            onChange={handleChange}
                          />
                          <Typography variant="body1">Beacon Token Mac</Typography>
                        </div>
                      </Grid>
                      {checkedState.beaconChecked && (
                        <Grid item xs={12}>
                          <Input
                            className={classes.input}
                            name="beaconTokenMac"
                            value={
                              checkedState.beaconChecked && tokenMacInput.beaconTokenMac
                                ? tokenMacInput.beaconTokenMac
                                : ''
                            }
                            onChange={(e) => handleTokenMac(e, 'beaconTokenMac')}
                            placeholder="Enter Beacon Token Mac"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    {/* Tracker Token Mac Section */}
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className={classes.checkboxLabel}>
                          <Checkbox
                            name="trackerChecked"
                            checked={checkedState.trackerChecked}
                            color="primary"
                            inputProps={{ 'aria-label': 'Tracker checkbox' }}
                            onChange={handleChange}
                          />
                          <Typography variant="body1">Tracker Token Mac</Typography>
                        </div>
                      </Grid>
                      {checkedState.trackerChecked && (
                        <Grid item xs={12}>
                          <Input
                            className={classes.input}
                            name="trackerTokenMac"
                            value={
                              checkedState.trackerChecked && tokenMacInput.trackerTokenMac
                                ? tokenMacInput.trackerTokenMac
                                : ''
                            }
                            onChange={(e) => handleTokenMac(e, 'trackerTokenMac')}
                            placeholder="Enter Tracker Token Mac"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAssetUpdate} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default AssetLocation;

AssetLocation.propTypes = {
  row: PropTypes.any,
  onChange: PropTypes.func,
  onCancelChanges: PropTypes.func,
  onApplyChanges: PropTypes.func,
  rows: PropTypes.any,
  setRows: PropTypes.func
};
