import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import useFetchAssetRecords from '../../hooks/useFetchAssetRecord';
import { createOptions } from '../../helper/helper';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  filterAssetWrapper: {
    marginLeft: '1rem',
    cursor: 'pointer'
  },
  nested: {
    paddingLeft: '2rem'
  }
}));

const FilterList = ({ filterAssetBy, setFilterAssetBy }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [filterList] = useState(['Category', 'Owner', 'Operator']);
  const { assetRecords } = useFetchAssetRecords();

  const categoryList = createOptions(assetRecords, 'category');
  const ownerList = createOptions(assetRecords, 'owner');
  const operatorList = createOptions(assetRecords, 'operator');

  const dataMap = {
    Category: categoryList,
    Owner: ownerList,
    Operator: operatorList
  };

  const handleToggle = (category, item) => () => {
    const scopedKey = `${category}:${item}`;
    const currentIndex = checked.indexOf(scopedKey);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      // Add to checked
      newChecked.push(scopedKey);
      setFilterAssetBy([...filterAssetBy, { category, item }]);
    } else {
      // Remove from checked
      newChecked.splice(currentIndex, 1);
      setFilterAssetBy(filterAssetBy.filter((entry) => !(entry.category === category && entry.item === item)));
    }

    setChecked(newChecked);
  };

  const handleExpand = (key) => {
    setExpanded((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <div className={classes.filterAssetWrapper}>
      <List className={classes.root} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {filterList.map((filterKey) => {
          const subItems = dataMap[filterKey] || [];
          const isExpanded = expanded[filterKey];

          return (
            <div key={filterKey}>
              <ListItem button onClick={() => handleExpand(filterKey)}>
                <ListItemText primary={filterKey} />
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {subItems.map((item, index) => {
                    const scopedKey = `${filterKey}:${item}`;
                    return (
                      <ListItem
                        key={scopedKey + index}
                        role={undefined}
                        dense
                        button
                        className={classes.nested}
                        onClick={handleToggle(filterKey, item)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(scopedKey) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              'aria-labelledby': `checkbox-list-label-${scopedKey}`
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={item} />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </div>
          );
        })}
      </List>
    </div>
  );
};

export default FilterList;

FilterList.propTypes = {
  setFilterAssetBy: PropTypes.func,
  filterAssetBy: PropTypes.array
};
