import { Button, DialogActions, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import assetService from '../../../../services/AssetService';
import { format } from 'date-fns';

const AssetLogs = ({ row, onCancelChanges }) => {
  const [assetLogs, setAssetLogs] = useState([]);

  useEffect(() => {
    const fetchAssetLogs = async () => {
      try {
        const assetLogs = row?.assetId && (await assetService.getAssetLogs(row.assetId));
        const logs = assetLogs?.data?.data?.operator;
        let formattedLogs = [];

        for (let i = 0; i < logs.length; i++) {
          if (logs[i + 1]) {
            formattedLogs.push({ ...logs[i], endDate: logs[i + 1].assignedDate });
          } else {
            formattedLogs.push({ ...logs[i] });
          }
        }

        setAssetLogs(formattedLogs.length > 0 ? formattedLogs : logs);
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error is:', error);
      }
    };
    fetchAssetLogs();
  }, [row.assetId]);

  return (
    <div>
      <DialogContent style={{ height: '700px' }}>
        <ul>
          {assetLogs.length > 0 &&
            [...assetLogs].reverse().map((log) => {
              return (
                <li key={log.assignedDate}>
                  Operator <b>{log.operator}</b> was assigned to this asset on{' '}
                  {format(new Date(log.assignedDate), 'dd/MM/yyyy')}
                  {log?.endDate ? <span> to {format(new Date(log.endDate), 'dd/MM/yyyy')} .</span> : '.'}
                </li>
              );
            })}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
};

export default AssetLogs;

AssetLogs.propTypes = {
  row: PropTypes.any,
  onCancelChanges: PropTypes.func
};
