import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import RemoveIcon from '@material-ui/icons/Remove';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import { Grid } from '@material-ui/core';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import PowerIcon from '@material-ui/icons/Power';
import NavigationIcon from '@material-ui/icons/Navigation';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import SpeedIcon from '@material-ui/icons/Speed';
import PropTypes from 'prop-types';
import { getUnixTime } from 'date-fns';
import { fetchAddress, formatDuration } from '../../../helper/helper';
import VehicleOdometer from './VehicleOdometer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },

  details: {
    alignItems: 'center'
  },
  column: {
    flexBasis: '33.33%'
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  titleTypography: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '15px',
    fontWeight: 500
  },
  typography: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '13px'
  },
  locationTypography: {
    width: '330px',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '13px'
  },
  nowTypography: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '13px',
    paddingTop: theme.spacing(1)
  }
}));

const TrackerNowTab = ({ assetRecords }) => {
  const classes = useStyles();
  const [location, setLocation] = useState('');

  const convertTime = (timestamp) => {
    const currentTimestamp = getUnixTime(new Date());
    const timestampDifference = currentTimestamp - timestamp;
    const timeDifference = formatDuration(timestampDifference);
    return timeDifference;
  };

  const vehicleTrackerRecord = assetRecords?.length > 0 && assetRecords[0];
  const trackerUpdatedTime = convertTime(vehicleTrackerRecord?.timestamp);

  useEffect(() => {
    const fetchTrackerAddress = async () => {
      try {
        if (assetRecords?.length > 0) {
          const location = await fetchAddress(vehicleTrackerRecord?.lat, vehicleTrackerRecord?.long);

          setLocation(location);
        }
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error is:', error);
      }
    };
    fetchTrackerAddress();
  }, [vehicleTrackerRecord.lat, assetRecords.length, vehicleTrackerRecord.long]);

  // Initialize all panels as expanded by default.
  const [expandedPanels, setExpandedPanels] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
    panel4: true,
    panel5: true,
    panel6: true
  });

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [panel]: isExpanded // Update the state based on panel ID.
    }));
  };

  return (
    <div className={classes.root}>
      {/* Accordion 1 : Location */}
      <Accordion expanded={!!expandedPanels['panel1']} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary
          expandIcon={expandedPanels['panel1'] ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Location</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container>
            <Grid item container style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.typography}>
                  <SignalCellular4BarIcon style={{ color: 'green', marginRight: '3px' }} />
                  Signal Level: {vehicleTrackerRecord?.gsmSignalLevel}
                </Typography>

                <Typography className={classes.typography}>
                  Status: {vehicleTrackerRecord?.movementStatus === true ? 'Moving' : 'Stopped'}
                </Typography>
              </Grid>

              <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.typography}> Latitude: {vehicleTrackerRecord?.lat}</Typography>
                <Typography className={classes.typography}>Speed: {vehicleTrackerRecord.positionSpeed}</Typography>
              </Grid>

              <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.typography}> Longitude: {vehicleTrackerRecord?.long}</Typography>
                <Typography className={classes.typography}>
                  Direction:
                  <NavigationIcon
                    style={{
                      color: '#1f94d1cc',
                      border: '1px solid rgb(181 207 220 / 51%)',
                      borderRadius: '50%',
                      transform: `rotate(${vehicleTrackerRecord.positionDirection}deg)`
                    }}
                  />
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.typography}>
                  Altitude: {vehicleTrackerRecord.positionAltitude} meters
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.locationTypography}>{location || 'No location found'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>

      {/* Accordion 2 : gsm */}
      <Accordion expanded={!!expandedPanels['panel2']} onChange={handleAccordionChange('panel2')}>
        <AccordionSummary
          expandIcon={expandedPanels['panel2'] ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>GSM</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container>
            <Grid item container style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item style={{ display: 'flex' }}>
                  <SignalCellular4BarIcon style={{ color: 'green' }} />
                  <Typography className={classes.typography}>
                    GSM: {vehicleTrackerRecord?.gsmSignalLevel || '100%'}
                  </Typography>
                </Grid>
                <Typography className={classes.typography}>{trackerUpdatedTime || ''}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>

      {/* Accordion 3 : inputs */}
      <Accordion expanded={!!expandedPanels['panel3']} onChange={handleAccordionChange('panel3')}>
        <AccordionSummary
          expandIcon={expandedPanels['panel3'] ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Inputs</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container>
            <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography className={classes.titleTypography}> Inputs</Typography>
              <Typography className={classes.typography}>{trackerUpdatedTime || ''}</Typography>
            </Grid>

            <Grid item container style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography className={classes.typography}>
                {`Panic button: ${vehicleTrackerRecord?.panicButton === true ? 'On' : 'Off'} `}
              </Typography>
              <Typography className={classes.typography}>
                {`Crash: ${vehicleTrackerRecord?.crash === true ? 'On' : 'Off'} `}
              </Typography>

              <Typography className={classes.typography}>
                {`Ignition: ${vehicleTrackerRecord?.engineIgnitionStatus === true ? 'On' : 'Off'}`}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>

      {/* Accordion 4 : odometer */}
      <Accordion expanded={!!expandedPanels['panel4']} onChange={handleAccordionChange('panel4')}>
        <AccordionSummary
          expandIcon={expandedPanels['panel4'] ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Odometer</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container>
            <Grid item container style={{ display: 'flex', flexDirection: 'column' }}>
              <VehicleOdometer mileage={vehicleTrackerRecord?.vehicleMileage} />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>

      {/* Accordion 5 : powerSupply */}
      <Accordion expanded={!!expandedPanels['panel5']} onChange={handleAccordionChange('panel5')}>
        <AccordionSummary
          expandIcon={expandedPanels['panel5'] ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Power supply</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container>
            <Grid item container style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item style={{ display: 'flex' }}>
                <BatteryChargingFullIcon style={{ color: 'green' }} />
                <Typography className={classes.typography}>
                  Battery Level:{vehicleTrackerRecord?.gsmSignalLevel || '100%'}
                </Typography>
              </Grid>

              <Typography className={classes.nowTypography}>Now</Typography>

              <Grid item style={{ display: 'flex' }}>
                <PowerIcon style={{ color: 'green' }} />
                <Typography className={classes.typography}>
                  External voltage: {vehicleTrackerRecord?.externalPowersourceVoltage || '100%'}
                </Typography>
              </Grid>

              <Typography className={classes.typography}>{trackerUpdatedTime}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>

      {/* Accordion 6 : OBD2&CAN  */}
      <Accordion expanded={!!expandedPanels['panel6']} onChange={handleAccordionChange('panel6')}>
        <AccordionSummary
          expandIcon={expandedPanels['panel6'] ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel6-content"
          id="panel6-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>OBD2&CAN </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container>
            <Grid item>
              <Typography className={classes.titleTypography}> OBD2&CAN </Typography>
            </Grid>

            <Grid item container style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item style={{ display: 'flex' }}>
                <LocalGasStationIcon style={{ color: 'grey' }} />
                <Typography className={classes.typography}>
                  {`Fuel level: ${vehicleTrackerRecord?.canFuelLevel || 0} %`}
                </Typography>
              </Grid>
              <Grid item style={{ display: 'flex' }}>
                <SpeedIcon style={{ color: 'grey' }} />
                <Typography className={classes.typography}>
                  {`RMP: ${vehicleTrackerRecord?.canEngineRPM || 0} rpm`}
                </Typography>
              </Grid>

              <Typography className={classes.nowTypography}>Odometer</Typography>

              <Grid item style={{ display: 'flex' }}>
                <SpeedIcon style={{ color: 'grey' }} />
                <Typography className={classes.typography}>
                  {`Wheel based speed: ${vehicleTrackerRecord?.canVehicleSpeed || 0} km/h`}
                </Typography>
              </Grid>
              <Grid item style={{ display: 'flex' }}>
                <i className="far fa-tachometer-alt" style={{ color: 'grey', fontSize: '1rem' }}></i>

                <Typography className={classes.typography}>
                  {`Throttle Position: ${vehicleTrackerRecord?.canThrottlePedalLevel || 0} %`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>
    </div>
  );
};

export default TrackerNowTab;

TrackerNowTab.propTypes = {
  assetRecords: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      timestamp: PropTypes.number,
      engineIgnitionStatus: PropTypes.bool,
      vehicleMileage: PropTypes.number,
      sleepModeEnum: PropTypes.number,
      protocolId: PropTypes.number,
      positionAltitude: PropTypes.number,
      positionDirection: PropTypes.number,
      positionSpeed: PropTypes.number,
      gsmSignalLevel: PropTypes.number, // number but its the percentage
      batteryCurrent: PropTypes.number, //its in volt
      batteryVoltage: PropTypes.number,
      externalPowersourceVoltage: PropTypes.number
    })
  )
};
