import { Grid } from '@material-ui/core';
import React from 'react';
import { default as ReactSelect, components } from 'react-select';
import useFetchAssetRecords from '../../hooks/useFetchAssetRecord';
import { createOptions } from '../../helper/helper';
import PropTypes from 'prop-types';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const AssetFilterSelect = ({ setFilter }) => {
  const { assetRecords } = useFetchAssetRecords();

  const categoryList = createOptions(assetRecords, 'category');
  const ownerList = createOptions(assetRecords, 'owner');
  const operatorList = createOptions(assetRecords, 'operator');

  const handleChange = (selectedOption, filterCategory) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      filterAssetBy: {
        ...(prevFilter.filterAssetBy || {}), // Ensure filterAssetBy is initialized
        [filterCategory]: selectedOption // Update specific category
      }
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <ReactSelect
          options={categoryList}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option
          }}
          onChange={(selectedOption) => handleChange(selectedOption, 'category')}
          defaultValue=""
          placeholder="Filter by category"
        />
      </Grid>

      <Grid item xs={4}>
        <ReactSelect
          options={ownerList}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option
          }}
          onChange={(selectedOption) => handleChange(selectedOption, 'owner')}
          defaultValue=""
          placeholder="Filter by owner"
        />
      </Grid>

      <Grid item xs={4}>
        <ReactSelect
          options={operatorList}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option
          }}
          onChange={(selectedOption) => handleChange(selectedOption, 'operator')}
          defaultValue=""
          placeholder="Filter by operator"
        />
      </Grid>
    </Grid>
  );
};

export default AssetFilterSelect;

AssetFilterSelect.propTypes = {
  setFilter: PropTypes.func
};
