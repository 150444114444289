import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import RemoveIcon from '@material-ui/icons/Remove';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getUnixTime } from 'date-fns';
import { fetchAddress, formatDuration } from '../../../helper/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },

  details: {
    alignItems: 'center'
  },
  column: {
    flexBasis: '33.33%'
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },

  typography: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '13px'
  },
  locationTypography: {
    width: '330px',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '13px'
  }
}));

const BeaconNowTab = ({ assetRecord }) => {
  const classes = useStyles();
  const [location, setLocation] = useState('');

  const convertTime = (timestamp) => {
    const currentTimestamp = getUnixTime(new Date());
    const timestampDifference = currentTimestamp - timestamp;
    const timeDifference = formatDuration(timestampDifference);
    return timeDifference;
  };

  const beaconRecord = assetRecord?.length > 0 && assetRecord[0];
  const beaconUpdatedTime = convertTime(beaconRecord?.timestamp);

  useEffect(() => {
    const fetchTrackerAddress = async () => {
      try {
        if (assetRecord?.length > 0) {
          const location = await fetchAddress(beaconRecord?.lat, beaconRecord?.long);

          setLocation(location);
        }
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error is:', error);
      }
    };
    fetchTrackerAddress();
  }, [beaconRecord.lat, assetRecord.length, beaconRecord.long]);

  // Initialize all panels as expanded by default.
  const [expandedPanels, setExpandedPanels] = useState({
    panel1: true
  });

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [panel]: isExpanded // Update the state based on panel ID.
    }));
  };

  return (
    <div className={classes.root}>
      {/* Accordion 1 : Bluetooth data details */}
      <Accordion expanded={!!expandedPanels['panel1']} onChange={handleAccordionChange('panel1')}>
        <AccordionSummary
          expandIcon={expandedPanels['panel1'] ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className={classes.column}>
            <Typography className={classes.heading}>Eddystone</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container>
            <Grid item container style={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.typography}>Power {beaconRecord?.beaconPower}</Typography>
                <Typography className={classes.typography}>{beaconUpdatedTime || ''}</Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.typography}>Rssi: {beaconRecord?.beaconRssi}</Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.typography}>InstanceID: {beaconRecord.beaconInstanceID}</Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.typography}>NamespaceID: {beaconRecord.beaconNamespaceID}</Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.typography}>UUID: {beaconRecord.beaconUUID}</Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.locationTypography}>{location || 'No location found'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>
    </div>
  );
};

export default BeaconNowTab;

BeaconNowTab.propTypes = {
  assetRecord: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      timestamp: PropTypes.number,
      engineIgnitionStatus: PropTypes.bool,
      vehicleMileage: PropTypes.number,
      sleepModeEnum: PropTypes.number,
      protocolId: PropTypes.number,
      positionAltitude: PropTypes.number,
      positionDirection: PropTypes.number,
      positionSpeed: PropTypes.number,
      gsmSignalLevel: PropTypes.number, // number but its the percentage
      batteryCurrent: PropTypes.number, //its in volt
      batteryVoltage: PropTypes.number,
      externalPowersourceVoltage: PropTypes.number
    })
  )
};
