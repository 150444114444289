import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//get tracker current position details from db
function getTrackerRecords() {
  const endpoint = BASE_URL + 'vehicleTracker/getTrackerRecords';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//get one month vehicle records
function getAMonthRecords(vehiclePayload) {
  const endpoint = BASE_URL + 'vehicleTracker/getAMonthRecords';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    },
    params: {
      vehiclePayload: vehiclePayload
    }
  });
}

//get daily distance of vehicle of selected deviceName
function getDailyDistance(vehiclePayload) {
  const endpoint = BASE_URL + 'vehicleTracker/getDailyDistance';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    },
    params: {
      vehiclePayload: vehiclePayload
    }
  });
}

function getTelemetryDailyDuration(vehiclePayload) {
  const endpoint = BASE_URL + 'vehicleTracker/getTelemetryDailyDuration';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    },
    params: {
      vehiclePayload: vehiclePayload
    }
  });
}

function getTelemetryRouteHistory(vehiclePayload) {
  const endpoint = BASE_URL + 'vehicleTracker/getTelemetryRouteHistory';
  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    },
    params: {
      vehiclePayload: vehiclePayload
    }
  });
}

const vehicleTrackerService = {
  getTrackerRecords,
  getAMonthRecords,
  getDailyDistance,
  getTelemetryDailyDuration,
  getTelemetryRouteHistory
};

export default vehicleTrackerService;
