import { useEffect, useState } from 'react';
import vehicleTrackerService from '../../../services/VehicleTrackerService';
import {
  getFormattedAssetWithTokenMac,
  getFormattedTrackerRecords,
  isLessThanAWeek,
  withInTenMinutes
} from '../helper/helper';
import assetService from '../../../services/AssetService';

import { socket } from '../../../socket';

export default function useFetchVehicleTrackerRecordThirtySecond(filter) {
  const [vehicleTrackerRecordThirtySecond, setVehicleTrackerRecordThirtySecond] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allAssetRecords, setAllAssetRecords] = useState([]);

  useEffect(() => {
    const fetchVehicleTrackerRecords = async () => {
      setLoading(true);
      try {
        const vehicleResponse = await vehicleTrackerService.getTrackerRecords();
        const formattedRecords = getFormattedTrackerRecords(vehicleResponse.data.data);

        //asset records with tokenMac
        const assetResponse = await assetService.getAssetWithTokenMac();
        const assetWithTokenMac = assetResponse?.data?.data;
        const assetFormattedRecords = getFormattedAssetWithTokenMac(assetWithTokenMac);
        const assetRecord = [...formattedRecords, ...assetFormattedRecords];
        setAllAssetRecords(assetRecord);

        // Apply filters
        const filteredRecords = assetRecord.filter((record) => {
          if (!record.timestamp) return false;

          if (filter.legendColor === 'All') return true;
          if (filter.legendColor === 'Green' && withInTenMinutes(record.timestamp)) return true;
          if (filter.legendColor === 'Yellow' && isLessThanAWeek(record.timestamp)) return true;
          if (filter.legendColor === 'Red' && !withInTenMinutes(record.timestamp) && !isLessThanAWeek(record.timestamp))
            return true;

          return false;
        });

        const searchRecord = filterSearchRecord(filteredRecords, filter.searchInput);

        const finalRecords = searchRecord.filter((record) => {
          if (filter.trackerState === 'All') return true;
          if (filter.trackerState === 'Stationary' && !record.engineIgnitionStatus) return true;
          if (filter.trackerState === 'Moving' && record.engineIgnitionStatus) return true;

          return false;
        });

        setVehicleTrackerRecordThirtySecond(finalRecords);
      } catch (error) {
        setError('Failed to fetch vehicle tracker records');
        // eslint-disable-next-line no-console, no-undef
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleTrackerRecords();

    const handleNewTrackerRecord = (newTrackerRecord) => {
      const formattedRecord = getFormattedTrackerRecords([newTrackerRecord])[0];

      // Update state with the new or updated record
      setVehicleTrackerRecordThirtySecond((prevRecords) => {
        const recordExists = prevRecords.some((record) => record.deviceName === formattedRecord.deviceName);

        if (recordExists) {
          // Update the existing record
          return prevRecords.map((record) =>
            record.deviceName === formattedRecord.deviceName ? formattedRecord : record
          );
        }

        // Add the new record
        return [formattedRecord, ...prevRecords];
      });
    };

    socket.on('newVehicleTrackerRecordThirtySecond', handleNewTrackerRecord);

    // Cleanup on unmount
    return () => {
      socket.off('newVehicleTrackerRecordThirtySecond', handleNewTrackerRecord);
    };
  }, [filter.legendColor, filter.searchInput, filter.trackerState]);

  return { vehicleTrackerRecordThirtySecond, loading, error, setVehicleTrackerRecordThirtySecond, allAssetRecords };
}

function filterSearchRecord(records, query) {
  return records.filter((record) => record.deviceName?.toLowerCase().includes(query.toLowerCase()));
}
