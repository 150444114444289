import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css'; // Ensure you've imported the theme

const VehicleOdometer = ({ mileage }) => {
  const [odometerValue, setOdometerValue] = useState('00000000');

  useEffect(() => {
    // Simulate updating the odometer value
    // eslint-disable-next-line no-undef
    setTimeout(() => {
      const formattedValue = String(mileage?.toFixed() || 12345).padStart(8, '0'); // Add leading zeros
      setOdometerValue(formattedValue);
    }, 1000);
  }, [mileage]);

  return (
    <div className="odometer-wrapper" style={{ fontFamily: 'monospace', fontSize: '16px' }}>
      {odometerValue.split('').map((digit, index) => (
        <Odometer key={index} value={digit} format="d" theme="default" />
      ))}
    </div>
  );
};

VehicleOdometer.propTypes = {
  mileage: PropTypes.number.isRequired
};

export default VehicleOdometer;
