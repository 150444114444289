import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

import PropTypes from 'prop-types';
import ListItemDetails from './ListItemDetails';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  listWrapper: {
    height: '893px',
    overflowY: 'auto'
  }
});

const VehicleTrackerBottomBar = ({
  vehicleTrackerRecords,
  setTrackerItemClicked,
  trackerItemClicked,
  setFilter,
  filter,
  setSearchInput,
  setDeviceId,
  assetTrackerRecordsFromDb
}) => {
  const classes = useStyles();
  const handleTrackerItem = (deviceName, deviceId) => {
    setTrackerItemClicked(true);
    setSearchInput(deviceName);
    setFilter({ ...filter, searchInput: deviceName });
    setDeviceId(deviceId);
  };

  return (
    <div className={classes.listWrapper}>
      {!trackerItemClicked && (
        <List component="nav" aria-label="main vehicle tracker folders">
          {assetTrackerRecordsFromDb.length > 0 ? (
            assetTrackerRecordsFromDb?.map((record, index) => {
              return (
                <div
                  key={
                    record.deviceId
                      ? record.deviceId
                      : record.beaconInstanceID
                      ? record.beaconInstanceID
                      : record.deviceName + index
                  }
                >
                  <Divider />
                  <ListItem button onClick={() => handleTrackerItem(record.deviceName, record.deviceId)}>
                    <ListItemIcon>{record.beaconInstanceID ? <BluetoothIcon /> : <DriveEtaIcon />}</ListItemIcon>
                    <ListItemDetails record={record} vehicleTrackerRecords={vehicleTrackerRecords} />
                  </ListItem>
                  <Divider />
                </div>
              );
            })
          ) : (
            <>
              <Divider />
              <p>No device found</p>
              <Divider />
            </>
          )}
        </List>
      )}
    </div>
  );
};

export default VehicleTrackerBottomBar;

VehicleTrackerBottomBar.propTypes = {
  vehicleTrackerRecords: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      timestamp: PropTypes.number,
      engineIgnitionStatus: PropTypes.bool,
      vehicleMileage: PropTypes.number,
      sleepModeEnum: PropTypes.number,
      protocolId: PropTypes.number,
      positionAltitude: PropTypes.number,
      positionDirection: PropTypes.number,
      positionSpeed: PropTypes.number,
      gsmSignalLevel: PropTypes.number, // number but its the percentage
      batteryCurrent: PropTypes.number, //its in volt
      batteryVoltage: PropTypes.number,
      externalPowersourceVoltage: PropTypes.number
    })
  ),
  assetTrackerRecordsFromDb: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      timestamp: PropTypes.number,
      engineIgnitionStatus: PropTypes.bool,
      vehicleMileage: PropTypes.number,
      sleepModeEnum: PropTypes.number,
      protocolId: PropTypes.number,
      positionAltitude: PropTypes.number,
      positionDirection: PropTypes.number,
      positionSpeed: PropTypes.number,
      gsmSignalLevel: PropTypes.number, // number but its the percentage
      batteryCurrent: PropTypes.number, //its in volt
      batteryVoltage: PropTypes.number,
      externalPowersourceVoltage: PropTypes.number
    })
  ),
  setTrackerItemClicked: PropTypes.func,
  setFilter: PropTypes.func,
  filter: PropTypes.shape({
    legendColor: PropTypes.string,
    searchInput: PropTypes.string
  }),
  setSearchInput: PropTypes.func,
  trackerItemClicked: PropTypes.bool,
  setDeviceId: PropTypes.func
};
