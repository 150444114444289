import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  legendWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  }
}));

const MapLegend = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.legendWrapper}>
        <LocationOnIcon style={{ color: 'green' }} />
        <p style={{ fontSize: '13px' }}>Live Location within 10 minutes</p>
      </div>
      <div className={classes.legendWrapper}>
        <LocationOnIcon style={{ color: 'orange' }} />
        <p style={{ fontSize: '13px' }}>Last known location within a week</p>
      </div>
      <div className={classes.legendWrapper}>
        <LocationOnIcon style={{ color: 'red' }} />
        <p style={{ fontSize: '13px' }}>No status for 1 week</p>
      </div>
    </div>
  );
};

export default MapLegend;
