import { useEffect, useState } from 'react';
import assetService from '../../../services/AssetService';
import { formattedAssets } from '../table/hepler';

export default function useFetchAssetRecords(filter) {
  const [assets, setAssets] = useState([]);
  const [assetsError, setAssetError] = useState(null);
  const [assetLoading, setAssetLoading] = useState(false);

  useEffect(() => {
    const fetchAssetRecords = async () => {
      try {
        setAssetLoading(true);
        setAssetError(null);

        const assetRecords = await assetService.getAssets();

        const assetsList = formattedAssets(assetRecords.data.data);

        const searchedRows = searchRows(assetsList, filter.searchQuery);

        setAssets(searchedRows);
      } catch (e) {
        setAssetError(e.message || 'Could not fetch asset records');
      } finally {
        setAssetLoading(false);
      }
    };

    fetchAssetRecords();
  }, [filter.searchQuery]);

  return { assets, setAssets, assetLoading, assetsError };
}

function searchRows(allRows, query) {
  const formattedQuery = query.toLowerCase().split(' ');

  const searchedRows = allRows.filter((r) => {
    return formattedQuery.every(
      (term) =>
        r.assetId?.includes(term) ||
        r.model?.toLowerCase().includes(term) ||
        r.make?.toLowerCase().includes(term) ||
        r.operator?.toLowerCase().includes(term) ||
        r.location?.toLowerCase().includes(term) ||
        r.description?.toLowerCase().includes(term) ||
        r.owner?.toLowerCase().includes(term) ||
        r.category?.toLowerCase().includes(term) ||
        r.supplier?.toLowerCase().includes(term) ||
        r.comments?.toLowerCase().includes(term) ||
        r.tokenMac?.toLowerCase().includes(term)
    );
  });

  return searchedRows;
}
