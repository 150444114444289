import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  filterAssetWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1rem 1rem 0 1rem',
    cursor: 'pointer'
  }
}));

const FilterAsset = ({ handleFilterList }) => {
  const classes = useStyles();

  return (
    <div className={classes.filterAssetWrapper}>
      <div onClick={handleFilterList}>
        <FilterListIcon />
      </div>
    </div>
  );
};

export default FilterAsset;

FilterAsset.propTypes = {
  handleFilterList: PropTypes.func
};
