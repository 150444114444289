import { useEffect, useState } from 'react';
import vendorService from '../../../services/VendorService';

export default function useFetchVendorRecords() {
  const [vendorRecords, setVendorRecords] = useState([]);
  const [vendorLoading, setVendorLoading] = useState(false);
  const [vendorError, setVendorError] = useState(null);

  useEffect(() => {
    const fetchVendorList = async () => {
      try {
        setVendorLoading(true);
        const vendorRecords = await vendorService.getVendorsFromQBO();

        const vendorListOption = vendorRecords.data.data.QueryResponse.Vendor.map((c) => {
          return { value: c.DisplayName, label: c.DisplayName };
        });

        setVendorRecords(vendorListOption);
      } catch (error) {
        setVendorError(
          error?.response?.data?.data?.Fault?.Error[0]?.Detail ||
            error?.response?.data?.message ||
            'Internal server error'
        );
      } finally {
        setVendorLoading(false);
      }
    };
    fetchVendorList();
  }, []);

  return { vendorRecords, vendorLoading, vendorError };
}
