import React from 'react';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NavigationIcon from '@material-ui/icons/Navigation';
import ReactSelect from 'react-select';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

const { Option } = components;

const IconOption = (props) => {
  const { Icon } = props.data;
  return (
    <Option {...props}>
      {Icon}
      <label style={{ marginLeft: '10px' }}>{props.data.label}</label>
    </Option>
  );
};

Option.propTypes = {
  props: PropTypes.shape({
    data: PropTypes.shape({
      label: PropTypes.string
    })
  })
};

export const AssetIcon = ({ handleSelectedIcon, mapIcon }) => {
  // Define the options with icons
  const options = [
    { value: 'bluetooth', label: 'Beacon', Icon: <BluetoothIcon /> },
    { value: 'vehicle', label: 'Vehicle Tracker', Icon: <DriveEtaIcon /> },
    { value: 'location', label: 'Location', Icon: <LocationOnIcon /> },
    { value: 'navigation', label: 'Navigation', Icon: <NavigationIcon /> }
  ];

  const mapIconValue = options.filter((option) => option.value === mapIcon);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body1">Map Icon</Typography>
      </Grid>
      <Grid item xs={12}>
        <ReactSelect
          value={mapIconValue || ''}
          placeholder="select map icons"
          options={options}
          isClearable={true}
          components={{ Option: IconOption }}
          onChange={(selectedOption) => {
            if (selectedOption === null) {
              handleSelectedIcon(null);
            } else {
              handleSelectedIcon(selectedOption);
            }
          }}
          // eslint-disable-next-line no-undef
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </Grid>
    </Grid>
  );
};

AssetIcon.propTypes = {
  handleSelectedIcon: PropTypes.func,
  mapIcon: PropTypes.string
};
