import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import InboxIcon from '@material-ui/icons/Inbox';
import ClassIcon from '@material-ui/icons/Class';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import SideNavItem from './SideNavItem';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const SideNavList = ({ mobileOpen, role, selectedItem, handleListItemClick, handleLargeDevice }) => {
  return (
    <div>
      {role && role === 'User' && (
        <>
          <Link to="/mytime">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="My Time"
              icon={<AccessTimeIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/myexpenses">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="My Expenses"
              icon={<AttachMoneyIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/approvetimesheets">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Approve Timesheets"
              icon={<CheckCircleOutlineIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/myasset">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="My Asset"
              icon={<EmojiTransportationIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>
        </>
      )}

      {role && role === 'Admin' && (
        <>
          <Link to="/dashboard">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Dashboard"
              icon={<DashboardIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/timesheets">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Timesheets"
              icon={<ListAltIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/expenses">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Expenses"
              icon={<AttachMoneyIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/users">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Users"
              icon={<PeopleAltIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/customers">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Customers"
              icon={<AccountBoxIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/suppliers">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Suppliers"
              icon={<PeopleAltIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/item">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Item"
              icon={<InboxIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/class">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Class"
              icon={<ClassIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/qboEmployee">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="QboEmployee"
              icon={<LibraryAddIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/setup">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Setup"
              icon={<SettingsIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/asset">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Asset"
              icon={<EmojiTransportationIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>

          <Link to="/maps">
            <SideNavItem
              selectedItem={selectedItem}
              mobileOpen={mobileOpen}
              handleListItemClick={handleListItemClick}
              handleLargeDevice={handleLargeDevice}
              primaryText="Asset Maps"
              icon={<LocationOnIcon style={{ fontSize: '1.2rem' }} />}
            />
          </Link>
        </>
      )}
    </div>
  );
};

export default SideNavList;

SideNavList.propTypes = {
  mobileOpen: PropTypes.bool,
  handleListItemClick: PropTypes.func,
  role: PropTypes.string,
  selectedItem: PropTypes.any,
  handleLargeDevice: PropTypes.func
};
