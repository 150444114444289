import React from 'react';
import MapComponent from './MapComponent';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  rightBarWrapper: {
    display: 'flex',
    flex: '2',
    border: '1px solid grey'
  }
});

const VehicleTrackerRightBar = ({ deviceId, vehicleTabClicked, dateString }) => {
  const classes = useStyles();
  return (
    <div className={classes.rightBarWrapper}>
      <MapComponent deviceId={deviceId} vehicleTabClicked={vehicleTabClicked} dateString={dateString} />
    </div>
  );
};

export default VehicleTrackerRightBar;

VehicleTrackerRightBar.propTypes = {
  deviceId: PropTypes.any,
  vehicleTabClicked: PropTypes.any,
  dateString: PropTypes.any
};
