import { z } from 'zod';

export function formattedAssets(assetRecords) {
  return assetRecords.map((item) => ({
    id: item._id,
    assetId: item.assetId || '',
    tokenMac: item.tokenMac || '',
    make: item.make || '',
    model: item.model || '',
    description: item.description || '',
    tradeSpecific: item.tradeSpecific || '',
    supplier: item.supplier || '',
    category: item.category || '',
    dateOfPurchase: item.dateOfPurchase || '',
    purchasePrice: Number(item.purchasePrice) || '',
    owner: item.owner || '',
    operator: item.operator || '',
    location: item.beaconLocation || item.location || '',
    locationDate: item.locationDate || '',
    insuranceName: item.insuranceName || '',
    insurancePolicy: item.insurancePolicy || '',
    insuranceExpiry: item.insuranceExpiry || '',
    maintenanceSchedule: item.maintenanceSchedule || '',
    disposalDate: item.disposalDate || '',
    image: item.image || '',
    comments: item.comments || '',
    activeStatus: item.activeStatus || false,
    fixedLocation: item.fixedLocation || false,
    maps: item.position || null,
    beaconTimestamp: item.beaconTimestamp || null,
    beaconTokenMac: item.beaconTokenMac || false,
    trackerTokenMac: item.trackerTokenMac || false,
    mapIcon: item.mapIcon || null
  }));
}

export function isChangedAssetImageValid(assetId, file) {
  const fileName = file.name.split('.')[0];

  if (assetId === fileName) {
    return true;
  }
  return false;
}

export function createOptions(rows, optionName) {
  // eslint-disable-next-line no-undef
  const uniqueOptions = new Set(rows.map((r) => r[optionName]).filter((option) => option && option.trim() !== ''));
  const options = Array.from(uniqueOptions).map((option) => {
    return { value: option, label: option };
  });
  return options;
}

export function isCommentValid(activeStatus, comment) {
  if (activeStatus === false && (comment === null || comment.trim() === '')) {
    return false;
  } else {
    return true;
  }
}

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
const maxFileSize = 10 * 1024 * 1024;

// const imageSchema = z.object({
//   name: z.string(),
//   size: z.number().max(maxFileSize, { message: 'File size should not exceed 3MB' }),
//   type: z.string().refine((type) => ACCEPTED_IMAGE_TYPES.includes(type), {
//     message: 'Invalid image type. Accepted types are .jpeg, .jpg, .png, .webp'
//   })
// });

const imageSchema = z
  .any()
  .refine((file) => file?.size <= maxFileSize, `Max image size is 3MB.`)
  .refine(
    (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
    'Only .jpg, .jpeg, .png and .webp formats are supported.'
  );

// const imageSchema =
//   // eslint-disable-next-line no-undef
//   (z.custom < Blob) |
//   // eslint-disable-next-line no-undef
//   (File >
//     ((val) => {
//       // eslint-disable-next-line no-undef
//       if (!(val instanceof Blob) && !(val instanceof File)) {
//         throw new Error('Image is required and must be a file');
//       }

//       if (val.size > maxFileSize) {
//         throw new Error('Image size must be less than 3MB');
//       }

//       if (!ACCEPTED_IMAGE_TYPES.includes(val.type)) {
//         throw new Error('Invalid file type. Accepted types are .jpeg, .jpg, .png, .webp');
//       }

//       return val;
//     }));

// const userVisibilitySchema = z.union([
//   z.object({
//     value: z.string(),
//     label: z.string(),
//     id: z.string().optional()
//   }),
//   z
//     .string()
//     .optional()
//     .refine((val) => val === '', { message: 'Invalid selection' }) // Allow empty string
// ]);

export const addAssetSchema = z.object({
  assetId: z.number().optional(), // This will be generated by createNewAssetId, so it's optional
  tokenMac: z.string().optional(),
  image: imageSchema,
  make: z.string().min(3, { message: 'Make is required and must be longer than 3 alphabets' }),
  model: z.string().min(3, { message: 'Model is required and must be longer than 3 alphabets' }),
  description: z.string().min(5, { message: 'Description is required and must be longer than 3 alphabets' }),
  owner: z.string().min(4, { message: 'Owner is required' }),
  supplier: z.string().min(4, { message: 'Supplier is required' }),
  category: z.string().min(4, { message: 'Category is required' }),
  tradeSpecific: z.string().optional(),
  dateOfPurchase: z
    .string()
    .min(1, { message: 'Date of purchase is required' })
    .refine(
      (val) => {
        const date = new Date(val);
        return !isNaN(date.getTime());
      },
      { message: 'Invalid date' }
    ),
  purchasePrice: z.string().min(1, { message: 'Purchase Price is required' }),
  operator: z.string().min(1, { message: 'Operator is required' }),
  location: z.string().min(1, { message: 'Location is required' }),
  locationDate: z
    .string()
    .min(1, { message: 'Location Date is required' })
    .refine(
      (val) => {
        const date = new Date(val);
        return !isNaN(date.getTime());
      },
      { message: 'Invalid date' }
    ),
  insuranceName: z.string().optional(),
  insurancePolicy: z.string().optional(),
  insuranceExpiry: z
    .string()
    .optional()
    .refine(
      (val) => {
        const date = new Date(val);
        return !isNaN(date.getTime());
      },
      { message: 'Invalid date' }
    ),
  maintenanceSchedule: z.string().optional(),
  disposalDate: z
    .string()
    .optional()
    .refine(
      (val) => {
        const date = new Date(val);
        return !isNaN(date.getTime());
      },
      { message: 'Invalid date' }
    ),
  comments: z.string().optional(),
  activeStatus: z.boolean(),
  fixedLocation: z.boolean(),
  mapIcon: z.string().optional(),
  beaconTokenMac: z.boolean(),
  trackerTokenMac: z.boolean(),
  beaconTokenMacInput: z.string().optional(),
  trackerTokenMacInput: z.string().optional()
});

export function isAssetIdAndImageNameValid(assetId, imageName) {
  if (assetId == imageName.split('.')[0]) {
    return true;
  } else {
    return false;
  }
}

export function findMissingAssetId(assetIds) {
  const numericIds = assetIds.map(Number);
  const max = Math.max(...numericIds); // Will find highest number
  const min = Math.min(...numericIds); // Will find lowest number
  let missingNumber;

  for (let i = min; i <= max; i++) {
    if (!numericIds.includes(i)) {
      // Checking whether i(current value) present in num(argument)
      missingNumber = i;
      break;
    }
  }
  return missingNumber;
}
