import React, { useState } from 'react';
import VehicleTrackerTopBar from './vehicleTrackerTopBar/VehicleTrackerTopBar';
import { makeStyles } from '@material-ui/core';
import VehicleTrackerBottomBar from './VehicleTrackerBottomBar/VehicleTrackerBottomBar';
import PropTypes from 'prop-types';
import useFetchVehicleTrackerRecordThirtySecond from '../hooks/useFetchVehicleTrackerRecordThirtySecond';
import useFetchVehicleTrackerRecordsFromDb from '../hooks/useFetchVehicleTrackerRecordsFromDb';

const useStyles = makeStyles({
  leftBarWrapper: {
    border: '1px solid grey',
    borderRadius: '4px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }
});

const VehicleTrackerLeftBar = ({
  trackerItemClicked,
  setTrackerItemClicked,
  deviceId,
  setDeviceId,
  vehicleTabClicked,
  setVehicleTabClicked,
  clickedDate,
  setClickedDate
}) => {
  const classes = useStyles();

  const [filter, setFilter] = useState({
    legendColor: 'All',
    searchInput: '',
    trackerState: 'All',
    filterAssetBy: null
  });

  const { vehicleTrackerRecordThirtySecond, allAssetRecords } = useFetchVehicleTrackerRecordThirtySecond(filter);

  const { assetTrackerRecordsFromDb } = useFetchVehicleTrackerRecordsFromDb(filter);
  const [searchInput, setSearchInput] = useState('');

  return (
    <div className={classes.leftBarWrapper}>
      <VehicleTrackerTopBar
        trackerItemClicked={trackerItemClicked}
        setFilter={setFilter}
        filter={filter}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
        vehicleTrackerRecords={vehicleTrackerRecordThirtySecond}
        setTrackerItemClicked={setTrackerItemClicked}
        allAssetRecords={allAssetRecords}
        setVehicleTabClicked={setVehicleTabClicked}
        vehicleTabClicked={vehicleTabClicked}
        deviceId={deviceId}
        setDeviceId={setDeviceId}
        clickedDate={clickedDate}
        setClickedDate={setClickedDate}
      />
      {assetTrackerRecordsFromDb && assetTrackerRecordsFromDb?.length > 0 && (
        <VehicleTrackerBottomBar
          vehicleTrackerRecords={vehicleTrackerRecordThirtySecond}
          setTrackerItemClicked={setTrackerItemClicked}
          trackerItemClicked={trackerItemClicked}
          setFilter={setFilter}
          filter={filter}
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          setDeviceId={setDeviceId}
          assetTrackerRecordsFromDb={assetTrackerRecordsFromDb}
        />
      )}
    </div>
  );
};

export default VehicleTrackerLeftBar;

VehicleTrackerLeftBar.propTypes = {
  trackerItemClicked: PropTypes.any,
  setTrackerItemClicked: PropTypes.func,
  deviceId: PropTypes.any,
  setDeviceId: PropTypes.func,
  vehicleTabClicked: PropTypes.any,
  setVehicleTabClicked: PropTypes.func,
  clickedDate: PropTypes.any,
  setClickedDate: PropTypes.func
};
