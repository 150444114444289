import { useEffect, useState } from 'react';
import assetService from '../../../services/AssetService';
import { formattedAssets } from '../../Asset/table/hepler';

export default function useFetchAssetRecords() {
  const [assetRecords, setAssetRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAssetRecords = async () => {
      setLoading(true);
      try {
        const assetResponse = await assetService.getAssets();
        const formattedRecords = formattedAssets(assetResponse.data.data);

        setAssetRecords(formattedRecords);
      } catch (error) {
        setError('Failed to fetch asset records');
        // eslint-disable-next-line no-console, no-undef
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssetRecords();
  }, []);

  return { assetRecords, loading, error, setAssetRecords };
}
