import { TextField } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const Calendar = ({ clickedDate, setClickedDate }) => {
  const handleChange = (e) => {
    setClickedDate(e.target.value);
  };

  return (
    <div>
      <TextField
        required={true}
        variant="outlined"
        size="small"
        id="date"
        name="date"
        type="date"
        value={clickedDate}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        style={{ width: '100%', paddingLeft: '1rem', paddingRight: '1rem' }}
      />
    </div>
  );
};

export default Calendar;
Calendar.propTypes = {
  clickedDate: PropTypes.any,
  setClickedDate: PropTypes.func
};
