import { ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  listItem: {
    '&:hover': {
      color: 'white'
    },
    borderLeft: (props) => (props.selected ? '4px solid green' : ''),
    color: (props) => (props.selected ? 'white' : '#8898aa')
  },
  listItemIcon: {
    color: (props) => (props.selected ? 'white' : '#8898aa'),
    minWidth: '25px'
  }
}));

const SideNavItem = ({ selectedItem, mobileOpen, handleListItemClick, handleLargeDevice, primaryText, icon }) => {
  const isSelected = selectedItem === primaryText;
  const classes = useStyles({ selected: isSelected });

  return (
    <div>
      <ListItem
        button
        onClick={mobileOpen ? () => handleListItemClick(primaryText) : () => handleLargeDevice(primaryText)}
        className={classes.listItem}
        selected={isSelected}
      >
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        <ListItemText primary={primaryText} />
      </ListItem>
    </div>
  );
};

export default SideNavItem;

SideNavItem.propTypes = {
  primaryText: PropTypes.string,
  mobileOpen: PropTypes.bool,
  handleListItemClick: PropTypes.func,
  role: PropTypes.string,
  selectedItem: PropTypes.string,
  handleLargeDevice: PropTypes.func,
  icon: PropTypes.any
};
