import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Checkbox, InputLabel, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { AssetIcon } from '../assetIcon/AssetIcon';
import { Controller } from 'react-hook-form';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      // marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2]
  },
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem',
    maxWidth: '500px',
    width: '100%'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: '100%'
  }
}));

function AddAssetLocation({
  control,
  errors,
  beaconTokenMacClicked,
  trackerTokenMacClicked,
  mapIcon,
  handleSelectedIcon,
  setValue
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        {/* fixedLocation and mapIcon */}
        <Grid container item spacing={3}>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: isSmall ? 'column' : 'row' }}>
            {/* asset icon */}
            <Grid item xs={12} style={{ marginRight: '1rem' }}>
              <AssetIcon handleSelectedIcon={handleSelectedIcon} mapIcon={mapIcon} />
            </Grid>

            {/* Fixed Location  Section */}
            <Grid item xs={12} className={classes.gridFixedLocationItem}>
              <Grid item container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="fixedLocation" className={classes.inputLabel}>
                    Fixed Location
                  </InputLabel>
                  <Controller
                    name="fixedLocation"
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <>
                        <Checkbox
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                          color="primary"
                          inputProps={{ 'aria-label': 'Fixed Location checkbox' }}
                        />
                        {errors.fixedLocation && (
                          <p
                            style={{
                              color: 'red',
                              marginTop: '10px',
                              fontSize: '0.8rem',
                              lineHeight: '1',
                              marginBottom: '0px',
                              paddingBottom: '0px'
                            }}
                          >
                            {errors?.fixedLocation?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* beaconTokenMac and gpsTokenMac */}
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel shrink htmlFor="beaconTokenMac" className={classes.inputLabel}>
                  Beacon Token Mac
                </InputLabel>
                <Controller
                  name="beaconTokenMac"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <>
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked), setValue('trackerTokenMac', false);
                          setValue('trackerTokenMacInput', '');
                        }}
                        color="primary"
                        inputProps={{ 'aria-label': 'Beacon Token Mac checkbox' }}
                      />
                      {errors.fixedLocation && (
                        <p
                          style={{
                            color: 'red',
                            marginTop: '10px',
                            fontSize: '0.8rem',
                            lineHeight: '1',
                            marginBottom: '0px',
                            paddingBottom: '0px'
                          }}
                        >
                          {errors?.fixedLocation?.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </Grid>
              {beaconTokenMacClicked && (
                <Grid item xs={12}>
                  <Controller
                    name="beaconTokenMacInput"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <BootstrapInput {...field} className={classes.bootstrapInput} />
                        {errors.beaconTokenMacInput && (
                          <p
                            style={{
                              color: 'red',
                              marginTop: '10px',
                              fontSize: '0.8rem',
                              lineHeight: '1',
                              marginBottom: '0px',
                              paddingBottom: '0px'
                            }}
                          >
                            {errors?.beaconTokenMacInput?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Tracker Token Mac Section */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel shrink htmlFor="trackerTokenMac" className={classes.inputLabel}>
                  GPS Token Mac
                </InputLabel>
                <Controller
                  name="trackerTokenMac"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <>
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked), setValue('beaconTokenMac', false);
                          setValue('beaconTokenMacInput', '');
                        }}
                        color="primary"
                        inputProps={{ 'aria-label': ' GPS Token Mac checkbox' }}
                      />
                      {errors.fixedLocation && (
                        <p
                          style={{
                            color: 'red',
                            marginTop: '10px',
                            fontSize: '0.8rem',
                            lineHeight: '1',
                            marginBottom: '0px',
                            paddingBottom: '0px'
                          }}
                        >
                          {errors?.fixedLocation?.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </Grid>
              {trackerTokenMacClicked && (
                <Grid item xs={12}>
                  <Controller
                    name="trackerTokenMacInput"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <BootstrapInput {...field} className={classes.bootstrapInput} />
                        {errors.trackerTokenMacInput && (
                          <p
                            style={{
                              color: 'red',
                              marginTop: '10px',
                              fontSize: '0.8rem',
                              lineHeight: '1',
                              marginBottom: '0px',
                              paddingBottom: '0px'
                            }}
                          >
                            {errors?.trackerTokenMacInput?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AddAssetLocation;

AddAssetLocation.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  beaconTokenMacClicked: PropTypes.any,
  trackerTokenMacClicked: PropTypes.any,
  mapIcon: PropTypes.any,
  setMapIcon: PropTypes.any,
  handleSelectedIcon: PropTypes.any,
  setValue: PropTypes.any
};
