/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  SelectionState,
  PagingState,
  IntegratedPaging,
  IntegratedSelection,
  DataTypeProvider
} from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableSelection, PagingPanel } from '@devexpress/dx-react-grid-material-ui';

import PropTypes from 'prop-types';
import { format } from 'date-fns';

import ImageFullScreenDialog from '../../../shared/dialog/ImageFullScreenDialog';

const ImageProvider = (props) => {
  return <DataTypeProvider formatterComponent={ImageFullScreenDialog} {...props} />;
};

const DateFormatter = ({ value }) => <span>{value ? format(new Date(value), 'dd/MM/yyyy') : null}</span>;

const DateTypeProvider = (props) => <DataTypeProvider {...props} formatterComponent={DateFormatter} />;

const getRowId = (row) => row.id;

const MyAssetTable = ({ rows, setRows, columns }) => {
  const [imageColumn] = useState(['image']);
  const [dateColumn] = useState(['dateOfPurchase', 'locationDate']);

  return (
    <div>
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <ImageProvider for={imageColumn} />
          <DateTypeProvider for={dateColumn} />

          <PagingState defaultCurrentPage={0} pageSize={100} />
          <IntegratedPaging />

          <Table />

          <TableHeaderRow />
          <PagingPanel />
        </Grid>
      </Paper>
    </div>
  );
};

export default MyAssetTable;

MyAssetTable.propTypes = {
  rows: PropTypes.any,
  setRows: PropTypes.func
};
