import React, { Component } from 'react';
import Footer from './Footer';
import '../css/Home.css';
import Dashboard from './Dashboard';
//import Graphs from './Graphs';
import Employees from './Employees';
import About from './About';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Vendor from './Vendor/Vendor';
import MyTime from './MyTime/MyTime';
import Timesheet from './admin/AdminTimesheet/Timesheet';
import Customer from './CustomerNew/Customer';
import Setup from './Setup/Setup';
import ApproveTimesheet from './admin/ApproveTimesheet/ApproveTimesheet';
import SideNav from './SideNav/SideNav';
import User from './UserNew/User';
import MyExpense from './MyExpense/MyExpense';
import Expense from './admin/AdminExpense/Expense';
import Item from './Item/Item';
import Class from './Class/Class';
import QboEmployee from './QboEmployee/QboEmployee';
import MyQualification from './MyQualification/MyQualification';
import MyProfileNew from './MyProfile/MyProfileNew';
import ApproveQualification from './ApproveQualification/ApproveQualification';
import RejectQualification from './RejectQualification/RejectQualification';
import Asset from './Asset/Asset';
import MyAsset from './MyAsset/MyAsset';
import NotificationList from './Notification/NotificationList/NotificationList';
import AdminQualification from './admin/AdminQualification/AdminQualification';
import AdminRequestLeave from './admin/AdminRequestLeave/AdminRequestLeave';
import VehicleTracker from './VehicleTracker/VehicleTracker';
//import Asset from './Asset/Asset';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line no-undef
      matches: window.matchMedia('(min-width: 600px)').matches
    };
  }

  // getLogout = () => {
  //   this.props.history.push('/login');
  // };

  render() {
    return (
      <Router>
        <div style={{ marginTop: '5rem', paddingLeft: '0.8rem ' }}>
          <div>
            <SideNav />
          </div>

          <div
            style={{
              marginLeft: this.state.matches ? '15rem' : '',
              padding: this.state.matches ? '1.5rem' : ''
            }}
          >
            <Switch>
              <Route path="/" exact component={Dashboard} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/employees" component={Employees} />
              <Route path="/users" component={User} />
              <Route path="/about" component={About} />
              <Route path="/mytime" component={MyTime} />
              <Route path="/myexpenses" component={MyExpense} />
              <Route path="/customers" component={Customer} />
              <Route path="/expenses" component={Expense} />
              <Route path="/timesheets" component={Timesheet} />
              <Route path="/approvetimesheets" component={ApproveTimesheet} />
              <Route path="/suppliers" component={Vendor} />
              <Route path="/item" component={Item} />
              <Route path="/setup" component={Setup} />
              <Route path="/asset" component={Asset} />
              <Route path="/myasset" component={MyAsset} />
              <Route path="/myprofile" component={MyProfileNew} />
              <Route path="/myqualification" component={MyQualification} />
              <Route path="/class" component={Class} />
              <Route path="/qboEmployee" component={QboEmployee} />
              <Route path="/qualification/:employeeId" component={AdminQualification} />
              {/* <Route path="/approve-qualification/:qId" component={ApproveQualification} />
              <Route path="/reject-qualification/:qId" component={RejectQualification} /> */}
              <Route path="/notification" component={NotificationList} />
              <Route path="/maps" component={VehicleTracker} />
              <Route path="/requestLeave/:employeeId" component={AdminRequestLeave} />
            </Switch>
          </div>
        </div>

        <Footer />
      </Router>
    );
  }
}

export default Home;
