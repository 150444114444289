import React, { useState } from 'react';
import VehicleTrackerLeftBar from './vehicleTrackerLeftBar/VehicleTrackerLeftBar';
import VehicleTrackerRightBar from './vehicleTrackerRightBar/VehicleTrackerRightBar';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  vehicleTrackerContainer: {
    display: 'flex',
    '@media only screen and (max-width: 460px)': {
      flexDirection: 'column'
    }
  }
});

const VehicleTracker = () => {
  const classes = useStyles();

  const [trackerItemClicked, setTrackerItemClicked] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [vehicleTabClicked, setVehicleTabClicked] = useState('now');

  let todayDate = new Date();
  todayDate = todayDate.toLocaleDateString();
  todayDate = todayDate.split('/').reverse().join('-');

  const [clickedDate, setClickedDate] = useState(todayDate);

  return (
    <div className={classes.vehicleTrackerContainer}>
      <div style={{ marginRight: '2rem' }}>
        <VehicleTrackerLeftBar
          trackerItemClicked={trackerItemClicked}
          setTrackerItemClicked={setTrackerItemClicked}
          deviceId={deviceId}
          setDeviceId={setDeviceId}
          vehicleTabClicked={vehicleTabClicked}
          setVehicleTabClicked={setVehicleTabClicked}
          clickedDate={clickedDate}
          setClickedDate={setClickedDate}
        />
      </div>

      <VehicleTrackerRightBar deviceId={deviceId} vehicleTabClicked={vehicleTabClicked} dateString={clickedDate} />
    </div>
  );
};

export default VehicleTracker;
