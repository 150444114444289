import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { fetchAddress } from '../../helper/helper';

const useStyles = makeStyles((theme) => ({
  detailWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '13px'
  },
  secondaryText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  detailItem: {
    display: 'flex',
    flex: '1'
  }
}));

const ListItemDetails = ({ record, vehicleTrackerRecords }) => {
  const classes = useStyles();

  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Check if the vehicleTrackerRecords have updated lat and long for this record
  const updatedCoordinates = useMemo(() => {
    const matchingRecord = vehicleTrackerRecords?.find(
      (vRecord) => vRecord.deviceId === record.deviceId && (vRecord.lat !== record.lat || vRecord.long !== record.long)
    );
    return matchingRecord ? { lat: matchingRecord.lat, long: matchingRecord.long } : null;
  }, [vehicleTrackerRecords, record.deviceId, record.lat, record.long]);

  useEffect(() => {
    const fetchTrackerAddress = async () => {
      const latToUse = updatedCoordinates?.lat || record.lat;
      const longToUse = updatedCoordinates?.long || record.long;
      if (!latToUse || !longToUse) return;

      setLoading(true);
      setError(null);

      try {
        const location = await fetchAddress(latToUse, longToUse);
        setAddress(location);
      } catch (err) {
        setError('Failed to fetch addresses. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTrackerAddress();
  }, [record.lat, record.long, updatedCoordinates]);

  return (
    <div>
      <ListItemText
        primary={record.deviceName}
        secondary={
          <Typography component="div" className={classes.secondaryText}>
            <div className={classes.detailWrapper}>
              <div className={classes.detailItem}>Coordinates:</div>
              <div className={classes.detailItem}>
                {updatedCoordinates
                  ? `${updatedCoordinates.lat}, ${updatedCoordinates.long}`
                  : `${record.lat}, ${record.long}`}
              </div>
            </div>
            <div className={classes.detailWrapper}>
              <div className={classes.detailItem}>Location:</div>
              {loading ? (
                <div className={classes.detailItem}>Loading...</div>
              ) : error ? (
                <div className={classes.detailItem}>{error}</div>
              ) : (
                <div className={classes.detailItem}>{address}</div>
              )}
            </div>
          </Typography>
        }
      />
    </div>
  );
};

export default ListItemDetails;

ListItemDetails.propTypes = {
  record: PropTypes.shape({
    deviceName: PropTypes.string,
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    timestamp: PropTypes.number,
    engineIgnitionStatus: PropTypes.bool,
    vehicleMileage: PropTypes.number,
    sleepModeEnum: PropTypes.number,
    protocolId: PropTypes.number,
    positionAltitude: PropTypes.number,
    positionDirection: PropTypes.number,
    positionSpeed: PropTypes.number,
    gsmSignalLevel: PropTypes.number, // number but its the percentage
    batteryCurrent: PropTypes.number, //its in volt
    batteryVoltage: PropTypes.number,
    externalPowersourceVoltage: PropTypes.number,
    deviceId: PropTypes.any
  }),
  vehicleTrackerRecords: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      timestamp: PropTypes.number,
      engineIgnitionStatus: PropTypes.bool,
      vehicleMileage: PropTypes.number,
      sleepModeEnum: PropTypes.number,
      protocolId: PropTypes.number,
      positionAltitude: PropTypes.number,
      positionDirection: PropTypes.number,
      positionSpeed: PropTypes.number,
      gsmSignalLevel: PropTypes.number, // number but its the percentage
      batteryCurrent: PropTypes.number, //its in volt
      batteryVoltage: PropTypes.number,
      externalPowersourceVoltage: PropTypes.number
    })
  )
};
