import React from 'react';
import AddAsset from '../../Asset/dialog/AddAsset';
import PropTypes from 'prop-types';

const AddNewAsset = ({ rows, open, setOpen, vendorRecords, vendorLoading, vendorError, setRows }) => {
  return (
    <div>
      <AddAsset
        open={open}
        setOpen={setOpen}
        rows={rows}
        setRows={setRows}
        vendorRecords={vendorRecords}
        vendorLoading={vendorLoading}
        vendorError={vendorError}
      />
    </div>
  );
};

export default AddNewAsset;

AddNewAsset.propTypes = {
  rows: PropTypes.array.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  vendorRecords: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  vendorLoading: PropTypes.bool,
  vendorError: PropTypes.string,
  setRows: PropTypes.func
};
